<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
        <el-button @click="onHandleZone('addData')" type="primary">
          新 增
        </el-button>
        <el-button @click="onHandleUpload('addData')" type="primary">
          批量新增
        </el-button>
      </div>
      <div class="d-flex headSearch">
        <div class="form-item">
          <el-input
            v-model="formData.product_name"
            placeholder="输入商品名称"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.sku_code"
            placeholder="输入货号"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.supplier_name"
            placeholder="输入供应商名称"
            clearable
          ></el-input>
        </div>
        <div class="form-item">
          <el-input
            v-model="formData.buyer_name"
            placeholder="输入业务员名称"
            clearable
          ></el-input>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.status"
            clearable
            placeholder="请选择商品状态"
          >
            <el-option
              :label="gItem.label"
              :value="gItem.value"
              v-for="(gItem, gIndex) in statusList"
              :key="gIndex"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item btn-wrap">
          <el-button type="primary" @click="onHandleRemove"
            >删除库存为0的商品</el-button
          >
        </div>
        <div class="form-item btn-wrap">
          <el-button
            @click="onHandleSearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>
        <div class="form-item btn-wrap">
          <el-button
            type="warning"
            icon="el-icon-upload2"
            @click="onHandleDownLoad"
            >导出</el-button
          >
        </div>
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        :id="formData.id"
        @submit-form="submitForm"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 新增弹窗 start -->
    <association-config-dialog
      ref="associationConfig"
      :id="formData.id"
      @submit-form="submitForm"
    ></association-config-dialog>
    <!-- 新增弹窗 end -->

    <!-- 上传表格弹窗 start -->
    <upload-file
      ref="uploadFileRef"
      @submit-form="submitForm"
      :id="formData.id"
    />
    <!-- 上传表格弹窗 end -->
  </section>
</template>
<script>
import associationConfigDialog from "./modules/addConfig/index.vue";
import {
  postVersatileRegionProductList,
  postVersatileRegionBindProductStockRemove,
} from "@/api/generalControl/zone";
import TableList from "./modules/table-list/index.vue";
import { GOODS_STATUS_ENUM } from "./utils/enum/index";
import UploadFile from "./modules/uploadFile/index";
import cloneDeep from "lodash/cloneDeep";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { getVersatileRegionProductOrderExport } from "@/api/export/index.js";

export default {
  name: "Association",
  components: { associationConfigDialog, TableList, UploadFile },
  data() {
    return {
      GOODS_STATUS_ENUM,
      statusList: Object.values(GOODS_STATUS_ENUM),
      total: 0,
      loadingData: false,
      formData: {
        id: "",
        product_name: "",
        sku_code: "",
        buyer_name: "",
        supplier_name: "",
        page: 1,
        pageSize: 10,
        status: 0, // 默认商品状态 为正常
      },
      formDataClone: {}, // 复制数据，用于初始化
      tableData: [], //列表数据
    };
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      this.formData.id = this.$route.query.id;
      this.formDataClone = cloneDeep(this.formData);
      this.initData();
    }
  },
  methods: {
    /**
     * 删除库存为0
     */
    async onHandleRemove() {
      try {
        const res = await postVersatileRegionBindProductStockRemove({
          id: this.formData.id,
        });
        console.log(res, "res==");
        this.submitForm();
      } catch (error) {
        console.log(error, "postVersatileRegionBindProductStockRemove");
      }
    },
    /**
     * 导出
     */
    async onHandleDownLoad() {
      this.$message.success("正在导出，请耐心等待");
      try {
        let params = { ...this.formData };
        delete params.page;
        delete params.pageSize;
        const res = await getVersatileRegionProductOrderExport(params);
        downLoadFlowBlob(res, res?.filename);
        this.$message.success("导出成功");
      } catch (err) {
        this.$message.error("导出失败了");
        console.log("ajax getVersatileRegionProductOrderExport err", err);
      }
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.postAjaxVersatileRegionProductList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.postAjaxVersatileRegionProductList();
    },
    /**
     * 查询
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.postAjaxVersatileRegionProductList();
    },
    /**
     * 初始化数据
     */
    initData() {
      this.postAjaxVersatileRegionProductList();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.postAjaxVersatileRegionProductList();
    },
    /**
     * 添加商品
     */
    onHandleZone(func, val) {
      this.$refs.associationConfig[func](val);
    },
    /**
     * 上传表格
     */
    onHandleUpload(func, val) {
      this.$refs.uploadFileRef[func](val);
    },
    /**
     * 刷新列表
     */
    submitForm() {
      this.formData.page = 1;
      this.postAjaxVersatileRegionProductList();
    },
    /**
     * 获得列表数据
     */
    async postAjaxVersatileRegionProductList() {
      this.loadingData = true;
      try {
        const { data } = await postVersatileRegionProductList(this.formData);
        this.tableData = data.data;
        this.total = data.total;
      } catch (err) {
        console.error("ajax postVersatileRegionProductList err", err);
      } finally {
        this.loadingData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .headSearch {
    flex: 1;
    justify-content: end;
    gap: 15px;
  }
}
</style>
