var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "page-wrap" },
    [
      _c("section", { staticClass: "form jn-form-style" }, [
        _c(
          "div",
          { staticClass: "form-item btn-wrap" },
          [
            _c("el-button", {
              staticClass: "refresh",
              staticStyle: { "margin-left": "5px" },
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.onHandleRefresh },
            }),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.onHandleZone("addData")
                  },
                },
              },
              [_vm._v(" 新 增 ")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.onHandleUpload("addData")
                  },
                },
              },
              [_vm._v(" 批量新增 ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "d-flex headSearch" }, [
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品名称", clearable: "" },
                model: {
                  value: _vm.formData.product_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "product_name", $$v)
                  },
                  expression: "formData.product_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c("el-input", {
                attrs: { placeholder: "输入货号", clearable: "" },
                model: {
                  value: _vm.formData.sku_code,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "sku_code", $$v)
                  },
                  expression: "formData.sku_code",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c("el-input", {
                attrs: { placeholder: "输入供应商名称", clearable: "" },
                model: {
                  value: _vm.formData.supplier_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "supplier_name", $$v)
                  },
                  expression: "formData.supplier_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c("el-input", {
                attrs: { placeholder: "输入业务员名称", clearable: "" },
                model: {
                  value: _vm.formData.buyer_name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "buyer_name", $$v)
                  },
                  expression: "formData.buyer_name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择商品状态" },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                },
                _vm._l(_vm.statusList, function (gItem, gIndex) {
                  return _c("el-option", {
                    key: gIndex,
                    attrs: { label: gItem.label, value: gItem.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item btn-wrap" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onHandleRemove },
                },
                [_vm._v("删除库存为0的商品")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item btn-wrap" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.onHandleSearch },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item btn-wrap" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", icon: "el-icon-upload2" },
                  on: { click: _vm.onHandleDownLoad },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "content-wrap" },
        [
          _c("table-list", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingData,
                expression: "loadingData",
              },
            ],
            attrs: { tableData: _vm.tableData, id: _vm.formData.id },
            on: { "submit-form": _vm.submitForm },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.formData.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.onHandleSizeChange,
              "current-change": _vm.onHandleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("association-config-dialog", {
        ref: "associationConfig",
        attrs: { id: _vm.formData.id },
        on: { "submit-form": _vm.submitForm },
      }),
      _c("upload-file", {
        ref: "uploadFileRef",
        attrs: { id: _vm.formData.id },
        on: { "submit-form": _vm.submitForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }